<template>
  <div>
    <v-parallax
      dark
      :src="imgSrc"
      height="400"
    >
      <v-overlay absolute opacity="0.3" class="ma-0 pa-0">
        <v-row
          align="center"
          justify="center"
        >
          <h1 :class="[$vuetify.breakpoint.mdAndUp ? 'h2' : 'h3']">
            Projects
          </h1>
          <v-container class="py-0">
            <hr>
          </v-container>
        </v-row>
        <v-row
          align="center"
          justify="center"
        >
          <v-container>
            <h1 class="text-center" :class="[$vuetify.breakpoint.mdAndUp ? 'h35' : 'h5']">
              Solar Energy
            </h1>
          </v-container>
        </v-row>
      </v-overlay>
    </v-parallax>

    <ProjectsBar />
    <SolarContent />
  </div>
</template>

<script>
import ProjectsBar from '@/components/hope/ProjectsBar.vue'
import SolarContent from '@/components/hope/SolarContent.vue'

export default {
  components: {
    ProjectsBar,
    SolarContent
  },
  data() {
    return {
      imgSrc: require('@/assets/images/IMG_1518.jpg')
    }
  },
  head: {
    title: function () {
      return {
        inner: 'Solar'
      }
    }
  }
}
</script>
